import React from "react";
import AdsRequestGrid from "../components/adsRequest";
import Service from "../service";
import { Paper } from "@mui/material";

const AdsRequest = () => {
  const [records, setRecords] = React.useState([]);
  const getRecords = () => {
    Service.get("adsRequest").then((response) => setRecords([...response]));
  };

  React.useEffect(() => {
    getRecords();
  }, []);

  if (records.length > 0) {
    return (
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AdsRequestGrid records={records} getRecords={getRecords} />
      </Paper>
    );
  } else {
    return null;
  }
};

export default AdsRequest;
