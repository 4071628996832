import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import moment from "moment";

const editButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Edit />
      </IconButton>
    </strong>
  );
};

const deleteButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Delete />
      </IconButton>
    </strong>
  );
};

export default function AnnounceGrid({
  records,
  handleDelete,
  handleEdit,
  handlePageChange,
}) {
  const recordList = records.docs.map((item) => {
    return {
      ...item,
      id: item._id,
      il: item.provienceCity?.il || "",
      ilce: item.provienceDistrict?.ilce || "",
      date: moment(item.date).format("DD-MM-YYYY"),
    };
  });

  const columns = [
    {
      field: "title",
      headerName: "Başlık",
      width: 200,
    },
    {
      field: "il",
      headerName: "İl",
      width: 100,
    },
    {
      field: "ilce",
      headerName: "İlçe",
      width: 100,
    },
    {
      field: "order",
      headerName: "Sırası",
      width: 50,
    },
    {
      field: "date",
      headerName: "Tarih",
      width: 150,
    },
    {
      field: "edit",
      headerName: "Düzenle",
      renderCell: (e) => editButton(e, handleEdit),
      width: 100,
    },
    {
      field: "delete",
      headerName: "Sil",
      renderCell: (e) => deleteButton(e, handleDelete),
      width: 100,
    },
  ];

  //TODO : farklı pagelerdeki silinme ve update işlemlerinde paginationda hata oluyor

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={recordList}
        columns={columns}
        pagination
        pageSize={records.limit}
        rowsPerPageOptions={[records.limit]}
        rowCount={records.totalDocs}
        paginationMode="server"
        onPageChange={(numb) => handlePageChange(numb + 1)}
        localeText={{ noResultsOverlayLabel: "Kayıt bulunamadı" }}
      />
    </div>
  );
}
