import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogContent, Tabs, Tab, MenuItem, Typography } from "@mui/material/";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";

import Service from "../service";

import DeceaseGrid from "../components/deceaseGrid";

import moment from "moment";
import { Search } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DeceseDialog = ({
  handleClose,
  handleAdd,
  handleUpdate,
  context,
  edit,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [homeCity, setHomeCity] = React.useState();
  const [burialCity, setBurialCity] = React.useState();
  const [homeDistrict, setHomeDistrict] = React.useState();
  const [burialDistrict, setBurialDistrict] = React.useState();
  const [homeTown, setHomeTown] = React.useState();
  const [burialTown, setBurialTown] = React.useState();
  const [homeNeighborhood, setHomeNeighborhood] = React.useState();
  const [burialNeighborhood, setBurialNeighborhood] = React.useState();

  const [homeCities, setHomeCities] = React.useState([]);
  const [burialCities, setBurialCities] = React.useState([]);
  const [homeDistricts, setHomeDistricts] = React.useState([]);
  const [burialDistricts, setBurialDistricts] = React.useState([]);
  const [homeTowns, setHomeTowns] = React.useState([]);
  const [burialTowns, setBurialTowns] = React.useState([]);
  const [burialNeighborhoods, setBurialNeighborhoods] = React.useState([]);
  const [homeNeighborhoods, setHomeNeighborhoods] = React.useState([]);

  const [show, setShow] = React.useState(false);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  React.useEffect(() => {
    const p1 = new Promise((resolve, reject) =>
      Service.get("adress/cities").then((cityRes) => {
        setHomeCities([...cityRes]);
        setHomeCity(context.homeCity?._id);
        if (context.homeDistrict?.id) {
          Service.get("adress/districts?id=" + context.homeCity?.id).then(
            (distRes) => {
              setHomeDistricts([...distRes]);
              setHomeDistrict(context.homeDistrict?._id);
              if (context.homeTown.id) {
                Service.get("adress/towns?id=" + context.homeDistrict?.id).then(
                  (townRes) => {
                    setHomeTowns([...townRes]);
                    setHomeTown(context.homeTown?._id);
                    if (context.homeNeighborhood?._id) {
                      Service.get(
                        "adress/neighborhoods?id=" + context.homeTown?.id
                      ).then((neigRes) => {
                        setHomeNeighborhoods([...neigRes]);
                        setHomeNeighborhood(context.homeNeighborhood?._id);
                        resolve(true);
                      });
                    } else {
                      resolve(true);
                    }
                  }
                );
              } else {
                resolve(true);
              }
            }
          );
        } else {
          resolve(true);
        }
      })
    );

    const p2 = new Promise((resolve, reject) =>
      Service.get("adress/cities").then((cityRes) => {
        setBurialCities([...cityRes]);
        setBurialCity(context.burialCity?._id);
        if (context.burialDistrict?.id) {
          Service.get("adress/districts?id=" + context.burialCity?.id).then(
            (distRes) => {
              setBurialDistricts([...distRes]);
              setBurialDistrict(context.burialDistrict?._id);
              if (context.burialTown?.id) {
                Service.get(
                  "adress/towns?id=" + context.burialDistrict?.id
                ).then((townRes) => {
                  setBurialTowns([...townRes]);
                  setBurialTown(context.burialTown?._id);
                  if (context.burialNeighborhood?._id) {
                    Service.get(
                      "adress/neighborhoods?id=" + context.burialTown?.id
                    ).then((neigRes) => {
                      setBurialNeighborhoods([...neigRes]);
                      setBurialNeighborhood(context.burialNeighborhood?._id);
                      resolve(true);
                    });
                  } else {
                    resolve(true);
                  }
                });
              } else {
                resolve(true);
              }
            }
          );
        } else {
          resolve(true);
        }
      })
    );

    Promise.all([p1, p2]).then(() => setShow(true));
  }, []);

  const changeHomeCity = (event) => {
    setHomeCity(event.target.value);
    const id = event.target.value;
    context.homeCity = id;
    context.homeDistrict = "";
    context.homeTown = "";
    context.homeNeighborhood = "";
    setHomeDistrict("");
    setHomeTown("");
    setHomeNeighborhood("");

    const cityID = homeCities.find((item) => item._id === id);
    Service.get("adress/districts?id=" + cityID.id).then((data) => {
      setHomeDistricts([...data]);
    });
  };
  const changeHomeDistrict = (event) => {
    setHomeDistrict(event.target.value);
    const id = event.target.value;
    const distID = homeDistricts.find((item) => item._id === id);
    context.homeDistrict = id;
    context.homeTown = "";
    context.homeNeighborhood = "";
    setHomeTown("");
    setHomeNeighborhood("");

    Service.get("adress/towns?id=" + distID.id).then((data) => {
      setHomeTowns([...data]);
    });
  };
  const changeHomeTown = (event) => {
    setHomeTown(event.target.value);
    const id = event.target.value;
    const townID = homeTowns.find((item) => item._id === id);
    context.homeTown = id;
    context.homeNeighborhood = "";
    setHomeNeighborhood("");

    Service.get("adress/neighborhoods?id=" + townID.id).then((data) => {
      setHomeNeighborhoods([...data]);
    });
  };
  const changeHomeNeighborhood = (event) => {
    setHomeNeighborhood(event.target.value);
    const id = event.target.value;
    context.homeNeighborhood = id;
  };

  const changeBuriualCity = (event) => {
    setBurialCity(event.target.value);
    const id = event.target.value;
    context.burialCity = id;
    context.burialDistrict = "";
    context.burialTown = "";
    context.burialNeighborhood = "";
    setBurialDistrict("");
    setBurialTown("");
    setBurialNeighborhood("");

    const cityID = burialCities.find((item) => item._id === id);

    Service.get("adress/districts?id=" + cityID.id).then((data) => {
      setBurialDistricts([...data]);
    });
  };
  const changeBuriualDistrict = (event) => {
    setBurialDistrict(event.target.value);
    const id = event.target.value;
    const distID = burialDistricts.find((item) => item._id === id);
    context.burialDistrict = id;
    context.burialTown = "";
    context.burialNeighborhood = "";
    setBurialTown("");
    setBurialNeighborhood("");

    Service.get("adress/towns?id=" + distID.id).then((data) => {
      setBurialTowns([...data]);
    });
  };
  const changeBuriualTown = (event) => {
    setBurialTown(event.target.value);
    const id = event.target.value;
    const townID = burialTowns.find((item) => item._id === id);
    context.burialTown = id;
    context.burialNeighborhood = "";
    setBurialNeighborhood("");

    Service.get("adress/neighborhoods?id=" + townID.id).then((data) => {
      setBurialNeighborhoods([...data]);
    });
  };
  const changeBuriualNeighborhood = (event) => {
    setBurialNeighborhood(event.target.value);
    const id = event.target.value;
    context.burialNeighborhood = id;
  };

  if (show) {
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          {edit ? " Düzenle" : " Ekle"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Merhum Kişisel Bilgiler" {...a11yProps(0)} />
              <Tab label="Merhum Adres Bilgileri" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <Grid container>
              <Grid item md={6} sm={12} sx={{ pr: 2 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  label={"Vefat Tarihi"}
                  type="date"
                  defaultValue={moment(context.deathDateTime).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => {
                    context.deathDateTime = e.target.value;
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  label={"Yayın Tarihi"}
                  type="date"
                  defaultValue={moment(context.bornDateTime).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => {
                    context.bornDateTime = e.target.value;
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.name}
                  onChange={(e) => {
                    context.name = e.target.value;
                  }}
                  label="Adı"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.surName}
                  onChange={(e) => {
                    context.surName = e.target.value;
                  }}
                  label="Soyadı"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.motherName}
                  onChange={(e) => {
                    context.motherName = e.target.value;
                  }}
                  label="Anne Adı"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.fatherName}
                  onChange={(e) => {
                    context.fatherName = e.target.value;
                  }}
                  label="Baba Adı"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.age}
                  onChange={(e) => {
                    context.age = e.target.value;
                  }}
                  label="Yaşı"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                />
              </Grid>
              <Grid item md={6} sm={12} sx={{ pr: 2 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.comment}
                  onChange={(e) => {
                    context.comment = e.target.value;
                  }}
                  label="Cenaze Namaz Vakti"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.prayerPlace}
                  onChange={(e) => {
                    context.prayerPlace = e.target.value;
                  }}
                  label="Cenaze Namaz Yeri"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.lat}
                  onChange={(e) => {
                    context.lat = e.target.value;
                  }}
                  label="Cenaze Namaz Yeri lat"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.long}
                  onChange={(e) => {
                    context.long = e.target.value;
                  }}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Cenaze Namaz Yeri long"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  defaultValue={context.burialPlace}
                  onChange={(e) => {
                    context.burialPlace = e.target.value;
                  }}
                  label="Defin Yeri"
                  autoFocus
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Grid container>
              <Grid item md={6} sm={12} sx={{ pr: 2 }}>
                <Typography variant={"h5"}>Memleket</Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="il"
                  value={homeCity || " "}
                  onChange={changeHomeCity}
                >
                  {homeCities.map((item) => (
                    <MenuItem key={item.id} value={item._id}>
                      {item.il}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="İlçe"
                  value={homeDistrict || " "}
                  onChange={changeHomeDistrict}
                >
                  {homeDistricts.map((item) => (
                    <MenuItem key={item.id} value={item._id}>
                      {item.ilce}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Kasaba"
                  value={homeTown || " "}
                  onChange={changeHomeTown}
                >
                  {homeTowns.map((item) => (
                    <MenuItem key={item.id} value={item._id}>
                      {item.semt_bucak_belde}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Mahalle"
                  value={homeNeighborhood || " "}
                  onChange={changeHomeNeighborhood}
                >
                  {homeNeighborhoods.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.mahalle}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} sm={12} sx={{ pr: 2 }}>
                <Typography variant={"h5"}>Vefat</Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="il"
                  value={burialCity || " "}
                  onChange={changeBuriualCity}
                >
                  {homeCities.map((item) => (
                    <MenuItem key={item.id} value={item._id}>
                      {item.il}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="İlçe"
                  value={burialDistrict || " "}
                  onChange={changeBuriualDistrict}
                >
                  {burialDistricts.map((item) => (
                    <MenuItem key={item.id} value={item._id}>
                      {item.ilce}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Kasaba"
                  value={burialTown || " "}
                  onChange={changeBuriualTown}
                >
                  {burialTowns.map((item) => (
                    <MenuItem key={item.id} value={item._id}>
                      {item.semt_bucak_belde}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Mahalle"
                  value={burialNeighborhood || " "}
                  onChange={changeBuriualNeighborhood}
                >
                  {burialNeighborhoods.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.mahalle}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </TabPanel>
          <DialogActions>
            <Button onClick={handleClose}>İptal</Button>
            <Button
              autoFocus
              onClick={() => {
                edit ? handleUpdate() : handleAdd();
              }}
            >
              Kaydet
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
};

const Decease = () => {
  const [records, setRecords] = React.useState();
  const [open, setOpen] = React.useState(false);

  const [edit, setEdit] = React.useState(false);

  const searchText = React.useRef();

  const context = React.useRef();

  const handleOpenAdd = () => {
    setOpen(true);
    setEdit(false);
    context.current = {
      bornDateTime: "",
      deathDateTime: "",
      age: 0,
      bornDateTime: "",
      burialCity: "",
      burialDistrict: "",
      burialNeighborhood: "",
      burialPlace: "",
      burialTown: "",
      comment: "",
      deathDateTime: "",
      fatherName: "",
      homeCity: "",
      homeDistrict: "",
      homeNeighborhood: "",
      homeTown: "",
      motherName: "",
      name: "",
      prayerPlace: "",
      lat: "",
      long: "",
      surName: "",
      status: "false",
    };
  };

  const handleOpenUpdate = (val) => {
    setOpen(true);
    setEdit(true);
    context.current = val;
  };

  const handleUpdate = () => {
    context.current.bornDateTime = moment(context.current.bornDateTime).format(
      "MM.DD.YYYY"
    );
    context.current.deathDateTime = moment(
      context.current.deathDateTime
    ).format("MM.DD.YYYY");

    context.current.burialCity = context.current.burialCity._id;
    context.current.burialDistrict = context.current.burialDistrict._id;
    context.current.burialNeighborhood = context.current.burialNeighborhood._id;
    context.current.burialTown = context.current.burialTown._id;

    context.current.homeCity = context.current.homeCity._id;
    context.current.homeDistrict = context.current.homeDistrict._id;
    context.current.homeNeighborhood = context.current.homeNeighborhood._id;
    context.current.homeTown = context.current.homeTown._id;

    Service.post("deceaseds/update", context.current).then((result) => {
      if (result.msg) {
        toast.error("Kayıt zaten var.");
      } else {
        toast.info("Kayıt Güncellendi");
        setOpen(false);
        searchText.current =
          context.current.name + " " + context.current.surName;
        handleSearch(1);
      }
    });
  };

  const handleAdd = () => {
    context.current.bornDateTime = moment(context.current.bornDateTime).format(
      "MM.DD.YYYY"
    );
    context.current.deathDateTime = moment(
      context.current.deathDateTime
    ).format("MM.DD.YYYY");

    Service.post("deceaseds/add", context.current).then((result) => {
      if (result.msg) {
        toast.error("Kayıt zaten var.");
      } else {
        toast.info("Kayıt Eklendi");
        setOpen(false);
        searchText.current =
          context.current.name + " " + context.current.surName;
        handleSearch(1);
      }
    });
  };

  const handleDelete = (val) => {
    if (window.confirm("Kayıt silinecek?")) {
      Service.delete("deceaseds/delete", val._id)
        .then((result) => {
          toast.info(" silindi.");
          searchText.current =
            context.current.name + " " + context.current.surName;
          handleSearch(1);
        })
        .catch((err) => toast.error(err));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (page) => {
    Service.getSearchWithPage(
      "deceaseds/search",
      searchText.current,
      page
    ).then((response) => {
      setRecords(response);
    });
  };

  React.useEffect(() => {}, [open]);

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <IconButton onClick={() => handleSearch()}>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Aramak istediğiniz merhumun adı ve soyadını giriniz"
                onChange={(e) => (searchText.current = e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "default" },
                }}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={handleOpenAdd}
              >
                Ekle
              </Button>
              <Tooltip title="Reload">
                <IconButton>
                  <RefreshIcon color="inherit" sx={{ display: "block" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {records?.docs && (
        <DeceaseGrid
          records={records}
          handleDelete={handleDelete}
          handleEdit={handleOpenUpdate}
          handlePageChange={handleSearch}
        />
      )}
      {open && (
        <DeceseDialog
          handleClose={handleClose}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          context={context.current}
          edit={edit}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Paper>
  );
};

export default Decease;
