import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import AnnounceGrid from "../components/announceGrid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";

import Service from "../service";
import MenuItem from "@mui/material/MenuItem";

import moment from "moment";

const active = [{ label: "active" }, { label: "passive" }];
const order = [
  { label: 1 },
  { label: 2 },
  { label: 3 },
  { label: 4 },
  { label: 5 },
  { label: 6 },
  { label: 7 },
  { label: 8 },
  { label: 9 },
  { label: 10 },
  { label: 11 },
  { label: 12 },
  { label: 13 },
  { label: 14 },
  { label: 15 },
  { label: 16 },
  { label: 17 },
  { label: 18 },
  { label: 19 },
  { label: 20 },
];

const AnnouncementDialog = ({
  handleClose,
  handleAdd,
  handleUpdate,
  context,
  edit,
}) => {
  const [city, setCity] = React.useState();
  const [district, setDistrict] = React.useState();
  const [districts, setDistricts] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const changeCity = (event) => {
    setCity(event.target.value);
    const id = event.target.value;
    const cityID = cities.find((item) => item._id === id);

    context.provienceCity = id;

    Service.get("adress/districts?id=" + cityID.id).then((data) => {
      setDistricts([...data]);
    });
  };

  const changeDistrict = (event) => {
    context.provienceDistrict = event.target.value;
    setDistrict(event.target.value);
  };

  React.useEffect(() => {
    new Promise((resolve, reject) =>
      Service.get("adress/cities").then((cityRes) => {
        setCities([...cityRes]);
        setCity(context.provienceCity._id);

        if (context.provienceDistrict.id) {
          Service.get("adress/districts?id=" + context.provienceCity.id).then(
            (distRes) => {
              setDistricts([...distRes]);
              setDistrict(context.provienceDistrict._id);
              resolve(true);
            }
          );
        } else {
          resolve(true);
        }
      })
    ).then((res) => {
      setShow(true);
    });
    //eslint-disable-next-line
  }, [show]);

  if (show) {
    return (
      <Dialog
        open={true}
        fullWidth={true}
        onClose={handleClose}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          {edit ? " Düzenle" : " Ekle"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={6} sm={12} sx={{ pr: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                autoFocus
                label={"Tarih"}
                type="date"
                defaultValue={moment(context.date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  context.date = e.target.value;
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label={"Başlık"}
                defaultValue={context.title}
                onChange={(e) => {
                  context.title = e.target.value;
                }}
                autoFocus
              />
              <TextField
                minRows={5}
                multiline
                margin="normal"
                required
                fullWidth
                label={"Duyuru"}
                defaultValue={context.announcement}
                onChange={(e) => {
                  context.announcement = e.target.value;
                }}
                autoFocus
              />
            </Grid>
            <Grid item md={6} sm={12} sx={{ pl: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                select
                label="İl"
                value={city || " "}
                onChange={changeCity}
              >
                {cities.map((item, index) => (
                  <MenuItem key={index} label={item.il} value={item._id}>
                    {item.il}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                margin="normal"
                fullWidth
                id="outlined-select-currency"
                select
                label="İlçe"
                value={district || " "}
                onChange={changeDistrict}
              >
                {districts.map((item, index) => (
                  <MenuItem key={index} label={item.ilce} value={item._id}>
                    {item.ilce}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                margin="normal"
                fullWidth
                select
                label="Statüsü"
                defaultValue={context.status}
                onChange={(e) => {
                  context.status = e.target.value;
                }}
              >
                {active.map((item, index) => (
                  <MenuItem key={index} label={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                margin="normal"
                fullWidth
                select
                label="Kaçıncı Duyuru"
                defaultValue={context.order}
                onChange={(e) => {
                  context.order = e.target.value;
                }}
              >
                {order.map((item, index) => (
                  <MenuItem key={index} label={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button
            autoFocus
            onClick={() => {
              edit ? handleUpdate() : handleAdd();
            }}
          >
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else return null;
};

const Announcement = () => {
  const [records, setRecords] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const context = React.useRef();

  const handleOpenAdd = () => {
    setOpen(true);
    setEdit(false);
    context.current = {
      announcement: "",
      date: "",
      provienceCity: "",
      provienceDistrict: "",
      order: 0,
      imgSrc: "",
      status: "",
      thumbImgSrc: "",
      title: "",
    };
  };

  const handleOpenUpdate = (val) => {
    setOpen(true);
    setEdit(true);
    context.current = val;
  };

  const handleUpdate = () => {
    context.current.date = moment(context.current.date).format("MM.DD.YYYY");
    if (context.current.provienceCity?.id)
      context.current.provienceCity = context.current.provienceCity._id;
    if (context.current.provienceDistrict?.id)
      context.current.provienceDistrict = context.current.provienceDistrict._id;

    Service.post("announcements/update", context.current).then((result) => {
      if (result.msg) {
        toast.error("Kayıt zaten var.");
      } else {
        toast.info("Kayıt Güncellendi");
        setOpen(false);
        getRecords();
      }
    });
  };

  const handleAdd = () => {
    context.current.date = moment(context.current.date).format("MM.DD.YYYY");
    Service.post("announcements/add", context.current).then((result) => {
      if (result.msg) {
        toast.error("Kayıt zaten var.");
      } else {
        toast.info("Kayıt Eklendi");
        setOpen(false);
        getRecords();
      }
    });
  };

  const handleDelete = (val) => {
    if (window.confirm("Kayıt silinecek?")) {
      Service.delete("announcements/delete", val._id)
        .then((result) => {
          toast.info(" silindi.");
          getRecords();
        })
        .catch((err) => toast.error(err));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRecords = (page) => {
    Service.getWithPage("announcements", page || 1).then((data) =>
      setRecords(data)
    );
  };

  React.useEffect(() => {
    getRecords();
  }, []);

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={handleOpenAdd}
              >
                Ekle
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {records?.docs && (
        <AnnounceGrid
          records={records}
          handleDelete={handleDelete}
          handleEdit={handleOpenUpdate}
          handlePageChange={getRecords}
        />
      )}
      {open && (
        <AnnouncementDialog
          handleClose={handleClose}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          context={context.current}
          edit={edit}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Paper>
  );
};

export default Announcement;
