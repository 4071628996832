import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  IconButton,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
} from "@mui/material";
import { Done, Visibility, Delete } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import Service from "../service";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

const confirmButton = (params, handler, getRecords) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row, getRecords);
        }}
      >
        <Delete />
      </IconButton>
    </strong>
  );
};

const showButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Visibility />
      </IconButton>
    </strong>
  );
};

export function DetailDialog({ data, handleClose }) {
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ad Soyad : {data.nameSurname}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Açıklama : {data.comment}</Typography>
            <Typography>İban : {data.iban}</Typography>
            <Typography>Sayfa : {data.page}</Typography>
            <Typography>Açıklama : {data.comment}</Typography>
            <Typography>
              Tarih : {moment(data.createdAt).format("DD/MM/YYYY - HH:MM")}
            </Typography>

            <br />
            <br />
            <a href={data.receiptImgSrc} target={"_blank"}>
              <img
                src={data.receiptImgSrc}
                style={{ width: 250, height: 200 }}
              />
            </a>
            <a href={data.receiptImgSrc} target={"_blank"}>
              <img
                src={data.requestImgSrc}
                style={{ width: 250, height: 200 }}
              />
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function AdsRequestGrid({ records, getRecords }) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (param, getRecords) => {
    if (window.confirm("Kayıt silinecek?")) {
      Service.post("adsRequest/confirm", param).then((data) => {
        toast.done("Silindi");
        getRecords();
      });
    }
  };

  const handleShow = (data) => {
    setData(data);
    setOpen(true);
  };

  const recordList = records.map((item) => {
    return {
      ...item,
      id: item._id,
    };
  });

  const columns = [
    {
      field: "nameSurname",
      headerName: "Ad Soyad",
      width: 130,
    },
    {
      field: "iban",
      headerName: "İban",
      width: 230,
    },
    {
      field: "page",
      headerName: "Kısım",
      width: 230,
    },
    {
      field: "comment",
      headerName: "Açıklama",
      width: 130,
    },
    {
      field: "show",
      headerName: "Görüntüle",
      renderCell: (e) => showButton(e, handleShow),
      width: 100,
    },
    {
      field: "confirm",
      headerName: "Sil",
      renderCell: (e) => confirmButton(e, handleConfirm, getRecords),
      width: 100,
    },
  ];

  //TODO : farklı pagelerdeki silinme ve update işlemlerinde paginationda hata oluyor

  return (
    <>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={recordList}
          columns={columns}
          pagination
          pageSize={15}
          rowCount={15}
        />
      </div>
      {open && <DetailDialog data={data} handleClose={handleClose} />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
