import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import ListView from "../components/datagrid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";

import Service from "../service";

const HodjaDialog = ({
  handleClose,
  handleAdd,
  handleUpdate,
  context,
  edit,
}) => {
  if (edit) {
    context.password = "";
  }

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle id="alert-dialog-title">
        {edit ? " Düzenle" : " Ekle"}
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={context.name}
            label={"Ad Soyad"}
            autoFocus
            onChange={(e) => (context.name = e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={context.email}
            label={"Kullanıcı Adı"}
            onChange={(e) => (context.email = e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={"Şifre"}
            onChange={(e) => (context.password = e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>İptal</Button>
        <Button
          autoFocus
          onClick={() => {
            edit ? handleUpdate() : handleAdd();
          }}
        >
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Hodja = () => {
  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const context = React.useRef();

  const handleOpenAdd = () => {
    setOpen(true);
    setEdit(false);
    context.current = {
      email: "",
      name: "",
      password: "",
    };
  };

  const handleOpenUpdate = (val) => {
    setOpen(true);
    setEdit(true);
    context.current = val;
  };

  const handleUpdate = () => {
    Service.post("users/update", context.current).then((result) => {
      if (result.msg) {
        toast.error("Kullanıcı zaten var.");
      } else {
        toast.info("Kayıt Güncellendi");
        setOpen(false);
        getUsers();
      }
    });
  };

  const handleAdd = () => {
    Service.post("users/add", context.current).then((result) => {
      if (result.msg) {
        toast.error("Kullanıcı zaten var.");
      } else {
        toast.info("Kayıt Eklendi");
        setOpen(false);
        getUsers();
      }
    });
  };

  const handleDelete = (val) => {
    if (window.confirm("Kayıt silinecek?")) {
      Service.delete("users/delete", val._id)
        .then((result) => {
          toast.info(result.email + " silindi.");
          getUsers();
        })
        .catch((err) => toast.error(err));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUsers = () => {
    Service.get("users/").then((data) => setUsers([...data]));
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={handleOpenAdd}
              >
                Ekle
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {users.length > 0 && (
        <ListView
          records={users}
          handleDelete={handleDelete}
          handleEdit={handleOpenUpdate}
        />
      )}
      {open && (
        <HodjaDialog
          handleClose={handleClose}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          context={context.current}
          edit={edit}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Paper>
  );
};

export default Hodja;
