import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./layouts/dashboard";

import Home from "./pages/home";
import Anouncement from "./pages/announcement";
import { Notification } from "./pages/notification";
import Hodja from "./pages/hodja";
import Decease from "./pages/decease";
import AdsRequest from "./pages/ads-request";
import { AdsMerhum } from "./pages/adsMerhum";
import { AdsCity } from "./pages/adsCity";
import { AdsMain } from "./pages/adsMain";
import { AdsDecease } from "./pages/adsDecease";
import NotFound from "./pages/404";
import Login from "./pages/login";

const token = localStorage.getItem("token");
const role = localStorage.getItem("role");

const AppRoutes = () => {
  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}>
            <Route index element={<Login />} />
            <Route path="*" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else {
    if (role === "admin") {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<Home />} />
              <Route path="/announcement" element={<Anouncement />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/hodja" element={<Hodja />} />
              <Route path="/decease" element={<Decease />} />
              <Route path="/adsrequest" element={<AdsRequest />} />
              <Route path="/adsMain" element={<AdsMain />} />
              <Route path="/adsCity" element={<AdsCity />} />
              <Route path="/adsDecease/:id" element={<AdsDecease />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<Decease />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
    }
  }
};

export default AppRoutes;
