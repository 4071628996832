import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  CardHeader,
  Card,
  CardMedia,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  CardActions,
  Select,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";

import Service from "../service";

import moment from "moment";
import { Edit, Favorite, Delete } from "@mui/icons-material";

const order = [
  { label: "Birinci", value: 1 },
  { label: "İkinci", value: 2 },
  { label: "Üçüncü", value: 3 },
  { label: "Dördüncü", value: 4 },
  { label: "Beşinci", value: 5 },
];

const MainAdvert = ({ context, handleOpenUpdate, handleDelete }) => {
  const { title, imgSrc, updatedAt } = context;
  if (context) {
    return (
      <Card sx={{ maxWidth: 250, mb: 2 }}>
        <CardHeader
          action={
            <>
              <IconButton onClick={() => handleOpenUpdate(context)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDelete(context)}>
                <Delete />
              </IconButton>
            </>
          }
          title={title}
          subheader={
            "Güncelleme tarihi " + moment(updatedAt).format("DD-MM-YYYY")
          }
        />
        <CardMedia component="img" image={imgSrc} alt={title} />
      </Card>
    );
  } else {
    return null;
  }
};

const AdsDialog = ({ handleClose, handleAdd, handleUpdate, context, edit }) => {
  React.useEffect(() => {}, []);

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <DialogTitle id="alert-dialog-title">
        {edit ? " Düzenle" : " Ekle"}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={12} sm={12} sx={{ pr: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={"Başlık"}
              defaultValue={context.title}
              onChange={(e) => {
                context.title = e.target.value;
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label={"Yönlenecek Site"}
              defaultValue={context.redirectUrl}
              onChange={(e) => {
                context.redirectUrl = e.target.value;
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="file"
              onChange={(e) => {
                context.imgSrc = e.target.files[0];
              }}
              autoFocus
            />

            <TextField
              margin="normal"
              fullWidth
              select
              label="Kaçıncı Reklam"
              defaultValue={context.order}
              onChange={(e) => {
                context.order = e.target.value;
              }}
            >
              {order.map((item, index) => (
                <MenuItem key={index} label={item.label} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>İptal</Button>
        <Button
          autoFocus
          onClick={() => {
            edit ? handleUpdate() : handleAdd();
          }}
        >
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AdsCity = () => {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [ads, setAds] = React.useState([]);

  const [cities, setCities] = React.useState([]);
  const [city, setCity] = React.useState();

  const context = React.useRef();
  //TODO İMAGE UPLOAD OLACAK
  const handleOpenAdd = () => {
    setOpen(true);
    setEdit(false);
    context.current = {
      title: "",
      redirectUrl: "",
      imgSrc: null,
      order: 0,
      city: "",
      deceaseds: "",
    };
  };

  const handleOpenUpdate = (val) => {
    setOpen(true);
    setEdit(true);
    context.current = val;
  };

  const handleUpdate = () => {
    context.current.city = city;
    Service.postWithImg("selaDepo/update", context.current, city).then(
      (result) => {
        if (result.msg) {
          toast.error("Kayıt zaten var.");
        } else {
          toast.info("Kayıt Güncellendi");
          setOpen(false);
          getRecords();
        }
      }
    );
  };

  const handleAdd = () => {
    context.current.city = city;
    Service.postWithImg("selaDepo/add", context.current, city).then(
      (result) => {
        if (result.msg) {
          toast.error("Kayıt zaten var.");
        } else {
          toast.info("Kayıt Eklendi");
          setOpen(false);
          getRecords();
        }
      }
    );
  };

  const handleDelete = (val) => {
    if (window.confirm("Kayıt silinecek?")) {
      Service.delete("selaDepo/delete", val._id)
        .then((result) => {
          toast.info(" silindi.");
          getRecords();
        })
        .catch((err) => toast.error(err));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetCity = (e) => {
    setCity(e.target.value);
    getRecords(e.target.value);
  };

  const getRecords = (id) => {
    const cityID = id || city;
    Service.get("selaDepo/getCity?id=" + cityID).then((data) => {
      setAds([...data]);
    });
  };

  React.useEffect(() => {
    Service.get("adress/cities").then((data) => setCities([...data]));
  }, []);

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid
              container
              flexDirection={"row"}
              justifyContent={"space-between"}
              sx={{ height: 100 }}
              alignItems={"center"}
            >
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel>İl Seçiniz</InputLabel>
                <Select
                  size={"small"}
                  value={city}
                  label="İl seçiniz"
                  onChange={handleSetCity}
                >
                  {cities.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.il}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {city && (
                <Button
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={handleOpenAdd}
                >
                  Ekle
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container>
        {city &&
          ads.map((data) => (
            <Grid item lg={4} md={4} key={data._id}>
              <MainAdvert
                context={data}
                handleOpenUpdate={handleOpenUpdate}
                handleDelete={handleDelete}
              />
            </Grid>
          ))}
      </Grid>
      {open && (
        <AdsDialog
          handleClose={handleClose}
          handleUpdate={handleUpdate}
          handleAdd={handleAdd}
          context={context.current}
          edit={edit}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Paper>
  );
};
