import React, { useEffect } from "react";
import DeceaseGridConfirm from "../components/deceaseGridConfirm";
import Service from "../service";

import { Paper } from "@mui/material";

const Home = () => {
  const [results, setResults] = React.useState([]);
  useEffect(() => {
    getRecords();
  }, []);

  const getRecords = () => {
    Service.get("deceaseds/getPassive").then((data) => {
      setResults([...data]);
    });
  };

  if (results) {
    return (
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <DeceaseGridConfirm records={results} getRecords={getRecords} />
      </Paper>
    );
  } else {
    return "null";
  }
};
export default Home;
