const url = "https://api.sela.tc/api/v1/";
//const url = "http://localhost:443/api/v1/";
const token = localStorage.getItem("token");

const Service = {
  get: (endpoint) => {
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    return fetch(url + endpoint, option).then((response) => response.json());
  },
  getWithPage: (endpoint, page) => {
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    return fetch(url + endpoint + "?page=" + page, option).then((response) =>
      response.json()
    );
  },
  post: (endpoint, data) => {
    const option = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    };

    return fetch(url + endpoint, option).then((response) => response.json());
  },
  postWithImg: (endpoint, data, il = "") => {
    const form = new FormData();
    form.append("title", data.title);
    form.append("redirectUrl", data.redirectUrl);
    form.append("imgSrc", data.imgSrc);
    form.append("order", data.order);
    form.append("city", il);
    form.append("condoglianze", data.condoglianze);
    form.append("deceaseds", data.deceaseds);
    form.append("_id", data._id);

    const option = {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: form,
    };

    return fetch(url + endpoint, option).then((response) => response.json());
  },
  delete: (endpoint, id) => {
    const option = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ _id: id }),
    };

    return fetch(url + endpoint, option).then((response) => response.json());
  },
  getSearchWithPage: (endpoint, text, page) => {
    if (!page) {
      page = 1;
    }
    const link = endpoint + "?page=" + page + "&text=" + text;

    return fetch(url + link, {
      method: "GET",
      headers: {},
    }).then((response) => {
      return response.json();
    });
  },
};

export default Service;
