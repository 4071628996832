import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Done, Money, NotificationAdd } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import Service from "../service";
import { toast } from "react-toastify";

const adsButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Money />
      </IconButton>
    </strong>
  );
};

const notButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <NotificationAdd />
      </IconButton>
    </strong>
  );
};

const confirmButton = (params, handler, getRecords) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row, getRecords);
        }}
      >
        <Done />
      </IconButton>
    </strong>
  );
};

export default function DeceaseGridConfirm({ records, getRecords }) {
  const navigate = useNavigate();

  const handleAds = (param) => {
    navigate("/adsDecease/" + param._id, {
      state: param,
    });
  };

  const handleConfirm = (param, getRecords) => {
    if (window.confirm("Kayıt onaylanacak?")) {
      Service.post("deceaseds/setActive", param).then((data) => {
        toast.done("onaylandı");
      });
      getRecords();
    }
  };

  const handleNotification = (param) => {
    if (window.confirm("Bildirim gönderilecek?")) {
      Service.post("deceaseds/notification", param).then((data) => {
        toast.done("Gönderildi");
      });
      getRecords();
    }
  };

  const recordList = records.map((item) => {
    return {
      ...item,
      id: item._id,
    };
  });

  const columns = [
    {
      field: "name",
      headerName: "Ad",
      width: 130,
    },
    {
      field: "surName",
      headerName: "Soyad",
      width: 130,
    },
    {
      field: "fatherName",
      headerName: "Baba Adı",
      width: 130,
    },
    {
      field: "burialPlace",
      headerName: "Defin Yeri",
      width: 200,
    },
    {
      field: "reklam",
      headerName: "Reklam - Taziye",
      renderCell: (e) => adsButton(e, handleAds),
      width: 130,
    },
    {
      field: "confirm",
      headerName: "Onayla",
      renderCell: (e) => confirmButton(e, handleConfirm, getRecords),
      width: 100,
    },
    {
      field: "notification",
      headerName: "Bildirim Gönder",
      renderCell: (e) => notButton(e, handleNotification),
      width: 100,
    },
  ];

  //TODO : farklı pagelerdeki silinme ve update işlemlerinde paginationda hata oluyor

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={recordList}
        columns={columns}
        pagination
        pageSize={5}
        rowCount={5}
      />
    </div>
  );
}
