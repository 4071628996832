import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const editButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Edit />
      </IconButton>
    </strong>
  );
};

const deleteButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Delete />
      </IconButton>
    </strong>
  );
};

export default function ListView({ records, handleDelete, handleEdit }) {
  const recordList = records.map((item) => {
    return {
      ...item,
      id: item._id,
    };
  });

  const columns = [
    {
      field: "name",
      headerName: "Adı Soyadı",
      width: 200,
    },
    {
      field: "email",
      headerName: "Kullanıcı Adı",
      width: 200,
    },
    {
      field: "role",
      headerName: "Rolü",
      width: 200,
    },
    {
      field: "edit",
      headerName: "Düzenle",
      renderCell: (e) => editButton(e, handleEdit),
      width: 100,
    },
    {
      field: "delete",
      headerName: "Sil",
      renderCell: (e) => deleteButton(e, handleDelete),
      width: 100,
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={recordList}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </div>
  );
}
