import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Edit, Delete, Money, NotificationAdd } from "@mui/icons-material";
import moment from "moment";
import Service from "../service";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

const editButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Edit />
      </IconButton>
    </strong>
  );
};

const deleteButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Delete />
      </IconButton>
    </strong>
  );
};

const notButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <NotificationAdd />
      </IconButton>
    </strong>
  );
};

const adsButton = (params, handler) => {
  return (
    <strong>
      <IconButton
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          handler(params.row);
        }}
      >
        <Money />
      </IconButton>
    </strong>
  );
};

export default function DeceaseGrid({
  records,
  handleDelete,
  handleEdit,
  handlePageChange,
}) {
  const navigate = useNavigate();

  const handleAds = (param) => {
    navigate("/adsDecease/" + param._id, {
      state: param,
    });
  };

  const handleNot = (param) => {
    if (window.confirm("Bildirim gönderilecek?")) {
      Service.post("deceaseds/notification", param).then((data) => {
        toast.done("Gönderildi");
      });
    }
  };

  const recordList = records.docs.map((item) => {
    return {
      ...item,
      id: item._id,

      il: item.homeCity?.il || "",
      ilce: item.homeDistrict?.ilce || "",
      /*
      ilce: item.provienceDistrict?.ilce || "",
      date: moment(item.date).format("DD-MM-YYYY"),
      */
    };
  });

  const columns = [
    {
      field: "name",
      headerName: "Ad",
      width: 100,
    },
    {
      field: "surName",
      headerName: "Soyad",
      width: 100,
    },
    {
      field: "fatherName",
      headerName: "Baba Adı",
      width: 100,
    },
    {
      field: "burialPlace",
      headerName: "Defin Yeri",
      width: 120,
    },
    {
      field: "il",
      headerName: "Vefat İli",
      width: 100,
    },
    {
      field: "ilce",
      headerName: "Vefat İlçesi",
      width: 100,
    },
    {
      field: "edit",
      headerName: "Düzenle",
      renderCell: (e) => editButton(e, handleEdit),
      width: 75,
    },
    {
      field: "delete",
      headerName: "Sil",
      renderCell: (e) => deleteButton(e, handleDelete),
      width: 60,
    },
    {
      field: "reklam",
      headerName: "Reklam -Taziye",
      renderCell: (e) => adsButton(e, handleAds),
      width: 100,
    },
    ,
    {
      field: "bildirim",
      headerName: "Bildirim",
      renderCell: (e) => notButton(e, handleNot),
      width: 60,
    },
  ];

  //TODO : farklı pagelerdeki silinme ve update işlemlerinde paginationda hata oluyor

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={recordList}
        columns={columns}
        pagination
        pageSize={records.limit}
        rowsPerPageOptions={[records.limit]}
        rowCount={records.totalDocs}
        paginationMode="server"
        onPageChange={(numb) => handlePageChange(numb + 1)}
        localeText={{ noResultsOverlayLabel: "Kayıt bulunamadı" }}
      />
    </div>
  );
}
