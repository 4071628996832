import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import PersonIcon from "@mui/icons-material/Person";

import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useLocation } from "react-router-dom";
import { Divider } from "@mui/material";

function Header(props) {
  const { onDrawerToggle } = props;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const categories = [
    {
      id: "AnaSayfa",
      url: "/",
    },
    {
      id: "İmam yönetimi",
      url: "/hodja",
    },
    { id: "Vefat işlemleri", url: "/decease" },
    {
      id: "Duyuru İşlemleri",
      url: "/announcement",
    },
    { id: "Bildirim Yönetimi", url: "/notification" },
    {
      id: "Reklam Ana Sayfa",
      url: "/adsMain",
    },
    {
      id: "Reklam İl",
      url: "/adsCity",
    },
    {
      id: "Reklam İstekleri",
      url: "/adsrequest",
    },
  ];

  const handleLogout = () => {
    localStorage.clear();
    window.location = "/";
  };

  const name = localStorage.getItem("name");

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />

            <Grid item>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Typography>{name}</Typography>

                <IconButton
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClick}
                >
                  <PersonIcon />
                </IconButton>
              </Grid>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>{name}</MenuItem>
                <MenuItem onClick={handleLogout}>Oturum kapat</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {categories.find((item) => item.url === location.pathname)
                  ? categories.find((item) => item.url === location.pathname).id
                  : "Merhum - Reklam - Taziye"}
              </Typography>
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0, boxShadow: "1px 3px 1px #9E9E9E" }}
      >
        <Divider />
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
